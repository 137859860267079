import styled from 'styled-components';

import theme from 'styles/theme';
import { Text, H3 } from 'components/Text';

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: white;
`;

export const Wrapper = styled.section`
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: white;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${theme.size.mobile}) {
    flex-direction: column;
    overflow: auto;
  }
`;

export const Form = styled.div`
  width: 550px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  padding: 60px 60px 30px;
  position: relative;
  z-index: 1;
  -webkit-overflow-scrolling: touch;

  .ie & {
    display: block;
  }

  @media (max-width: ${theme.size.tablet}) {
    padding: 60px 40px;
  }

  @media (max-width: ${theme.size.mobile}) {
    overflow: visible;
    width: 100%;
    height: 100%;
    padding: 60px ${theme.gutter};
  }
`;

export const Logo = styled.img`
  height: 55px;
  width: auto;
  transition: ${theme.transition};

  .ie & {
    margin-bottom: 100px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Inner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled(H3)`
  margin-bottom: 0;
`;

export const Subheading = styled(Text)`
  line-height: 1.4;
  max-width: 90%;
  margin-top: 10px;
  color: ${theme.color.gray2};
  font-size: ${theme.font.size.epsilon};
  -webkit-font-smoothing: subpixel-antialiased;

  .ie & {
    display: none;
  }
`;

export const Message = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  line-height: 1.25;
  align-items: center;
  justify-content: space-between;
  color: ${theme.color.ink};
  margin-top: 10px;
  border-top: 1px solid ${theme.color.paper2};
  font-size: ${theme.font.size.epsilon};
`;

export const Image = styled.div`
  width: calc(100% - 550px);
  position: relative;
  background-color: ${theme.color.paper};

  & > div {
    position: absolute !important;
    height: 100%;
    left: 0;
  }

  @media (max-width: ${theme.size.mobile}) {
    display: none;
  }
`;
