import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { UserContext } from 'utils/firebase';
import { ImageBlock } from 'components/Blocks';

import logoImage from 'img/logo-lab-dark.svg';

import {
  Wrapper,
  Form,
  Inner,
  Heading,
  Subheading,
  Logo,
  Image
} from './styles';
import LoginForm from './LoginForm';

export const LoginPage = ({ page }) => {
  const firebase = useContext(UserContext);

  return (
    <Wrapper>
      <Form>
        <Link to="/">
          <Logo src={logoImage} alt="Pelatum" />
        </Link>
        <Inner>
          <Heading>{page.form.heading}</Heading>
          {page.form.subheading && (
            <Subheading>{page.form.subheading}</Subheading>
          )}
          <LoginForm firebase={firebase} />
          {/* <Message to="/">
            <Text flex="1">{page.form.cta}</Text>
            <Button
              ml="10px"
              size="small"
              shade="pink"
              to={page.form.ctaButtonPath}
            >
              {page.form.ctaButtonText}
            </Button>
          </Message> */}
        </Inner>
      </Form>
      <Image>
        <ImageBlock content={page.page.background} />
      </Image>
    </Wrapper>
  );
};

export default LoginPage;
