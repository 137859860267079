import styled from 'styled-components';
import { lighten, transparentize } from 'polished';

import theme from 'styles/theme';
import StyledButton from 'components/Button';

export const Form = styled.form`
  position: relative;
  margin: 30px 0;
`;

export const Input = styled.input`
  padding: 20px 22px 22px;
  border: none;
  width: 100%;
  line-height: 1.3;
  margin-bottom: 5px;
  border-radius: ${theme.radius};
  transition: ${theme.transition};
  background-color: ${lighten(0.03, theme.color.paper)};

  &:focus,
  &:hover {
    outline: none;
    background-color: ${theme.color.paper};
  }
`;

export const Button = styled(StyledButton)`
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${theme.color.indigo};
  font-size: ${theme.font.size.epsilon};

  &:hover {
    background-color: ${theme.color.indigo2};
  }
`;

export const Message = styled.div`
  margin-top: 5px;
  line-height: 1.3;
  color: ${theme.color.pink};
  padding: 15px 22px;
  border-radius: ${theme.radius};
  background-color: ${transparentize(0.92, theme.color.pink)};
  font-size: ${theme.font.size.epsilon};
`;
