import React from "react";

import { Wrapper, Quote, Author } from "./styles";

const QuoteBlock = ({ content, shade, ...props }) => {
  return (
    <Wrapper {...props}>
      <Quote shade={shade}>{content.quote}</Quote>
      {content.author && <Author shade={shade}>— {content.author}</Author>}
    </Wrapper>
  );
};

export default QuoteBlock;
