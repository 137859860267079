import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import theme from 'styles/theme';
import { UserContext } from 'utils/firebase';
import { Masonry } from 'components/Grid';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import ResourcesCard from 'components/ResourcesCard';

import LoginPage from 'templates/home-page/LoginPage';
import Experiences from 'templates/home-page/Experiences';
import {
  Wrapper,
  Inner,
  Title,
  LoaderWrapper
} from 'templates/home-page/styles';

export const HomePageTemplate = ({
  page,
  experiences,
  resources,
  location
}) => {
  const firebase = useContext(UserContext);

  const breakpoints = {
    default: 4,
    1000: 3,
    800: 2,
    600: 1
  };

  if (!firebase.readyToFetch) {
    return (
      <LoaderWrapper>
        <Loader size="80px" color={theme.color.teal} />
      </LoaderWrapper>
    );
  }

  // if (firebase.refUser && firebase.refUser.isActive) {
  //   navigate("/");
  //   return null;
  // }

  if (!firebase.user) {
    return <LoginPage page={page.login} location={location} />;
  }

  return (
    <>
      <Wrapper bg={theme.color.indigo2}>
        <Inner>
          <Title>Storytelling</Title>
        </Inner>
        <Experiences content={experiences} />
      </Wrapper>
      <Wrapper>
        <Inner>
          <Title>Team Resources</Title>
          <Masonry breakpointCols={breakpoints} gutter="12px">
            {resources.map(item => {
              const { slug } = item.node.fields;
              const resource = item.node.frontmatter;
              return (
                <ResourcesCard
                  to={slug}
                  key={item.node.id}
                  content={resource}
                />
              );
            })}
          </Masonry>
        </Inner>
      </Wrapper>
    </>
  );
};

const HomePage = ({ data, location }) => {
  const { frontmatter: page } = data.page.edges[0].node;
  const { edges: experiences } = data.experiences;
  const { edges: resources } = data.resources;

  return (
    <Layout content={page} shade="dark">
      <HomePageTemplate
        page={page}
        location={location}
        experiences={experiences}
        resources={resources}
      />
    </Layout>
  );
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage {
    page: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            page {
              title
              background {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 30) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            cta {
              heading
              description
              buttonText
              buttonPath
            }
            footer {
              background {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 60) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            login {
              page {
                title
                background {
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 80) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              form {
                heading
                subheading
                cta
                ctaButtonText
                ctaButtonPath
              }
            }
          }
        }
      }
    }
    experiences: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { templateKey: { eq: "resource-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            order
            category
            page {
              title
              image {
                childImageSharp {
                  fluid(
                    maxWidth: 500
                    quality: 60
                    duotone: { highlight: "#ffffff", shadow: "#0994C6" }
                  ) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    resources: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { templateKey: { eq: "resources-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            order
            page {
              title
              description
              image {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 60) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            color
          }
        }
      }
    }
  }
`;
