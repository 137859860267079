import React from 'react';

import nextIcon from 'img/arrow-right-white.svg';
import prevIcon from 'img/arrow-left-white.svg';

import { Wrapper, Icon } from './styles';

const ArrowButton = ({ onClick, type }) => {
  const icon = type === 'next' ? nextIcon : prevIcon;
  const alt = type === 'next' ? 'Next Module' : 'Previous Module';
  return (
    <Wrapper type={type} onClick={onClick}>
      <Icon type={type} src={icon} alt={alt} />
    </Wrapper>
  );
};

export default ArrowButton;
