import styled from 'styled-components';
import { transparentize } from 'polished';

import theme from 'styles/theme';
import Button from 'components/Button';

export const Wrapper = styled(Button)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  padding: 0;
  border: none;
  z-index: 10;
  transform: translateY(-50%);
  backdrop-filter: blur(3px);
  background-color: ${transparentize(0.4, theme.color.indigo3)};
  left: ${props => (props.type === 'next' ? 'auto' : '12px')};
  right: ${props => (props.type === 'next' ? '12px' : 'auto')};

  &:hover {
    background-color: ${transparentize(0.2, theme.color.indigo3)};
  }

  &:active {
    transform: scale(0.9) translateY(-50%);
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  display: block;
  margin: auto;
  transform: ${props =>
    props.type === 'next' ? 'translateX(1px)' : 'translateX(-1px)'};
`;
