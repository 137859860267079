import React, { Component } from 'react';

import { Form, Input, Message, Button } from './styles';

class LoginForm extends Component {
  state = {
    displayName: '',
    email: '',
    password: '',
    error: ''
  };

  handleSubmit = event => {
    event.preventDefault();

    const { firebase } = this.props;
    const { email, password } = this.state;

    firebase.logIn(email, password);

    this.setState({ error: firebase.error });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const data = { [name]: value };

    this.setState(data);
  };

  render() {
    const { email, password, error } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Input
          name="email"
          placeholder="Email"
          value={email}
          onChange={this.handleChange}
        />
        <Input
          name="password"
          type="password"
          value={password}
          placeholder="Password"
          onChange={this.handleChange}
        />
        {error && <Message role="alert">{error}</Message>}
        <Button shade="primary" type="submit" onClick={this.handleSubmit}>
          Login
        </Button>
      </Form>
    );
  }
}

export default LoginForm;
