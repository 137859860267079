import React from "react";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

import Button from "components/Button";
import { Container, Block } from "components/Grid";

import { Heading, Content } from "./styles";

const ContentBlock = ({ content, shade, ...props }) => {
  const text = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content.text)
    .toString();

  return (
    <Container alignItems="flex-start" {...props}>
      <Block pr={["0", "40px"]} width={["100%", "40%"]}>
        <Heading shade={shade}>{content.heading}</Heading>
        {content.buttonPath && content.buttonText && (
          <Button
            mt={["20px", "40px"]}
            mb="30px"
            shade={shade}
            to={content.buttonPath}
          >
            {content.buttonText}
          </Button>
        )}
      </Block>
      <Block width={["100%", "60%"]}>
        <Content shade={shade} dangerouslySetInnerHTML={{ __html: text }} />
      </Block>
    </Container>
  );
};

export default ContentBlock;
