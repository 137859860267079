import styled from 'styled-components';

import theme from 'styles/theme';
import { Container, Grid } from 'components/Grid';
import { H3 } from 'components/Text';

export const Wrapper = styled(Container)`
  padding: 60px 0;
  position: relative;
  z-index: 3;

  .my-masonry-grid_column:nth-child(even) {
    margin-top: 40px;
  }

  @media (max-width: ${theme.size.tablet}) {
    padding: 50px 0;
  }
`;

export const Inner = styled(Grid)`
  width: 100%;
`;

export const Title = styled(H3)`
  color: white;
  margin-bottom: 30px;

  @media (max-width: ${theme.size.tablet}) {
    margin-bottom: 15px;
  }
`;

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;
