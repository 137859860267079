import styled from "styled-components";
import styledMap from "styled-map";
import { borders, space, zIndex } from "styled-system";

import theme from "styles/theme";
import { H2, Text } from "components/Text";

export const Wrapper = styled.div`
  max-width: 850px;
  margin: auto;
  text-align: center;
  position: relative;
  padding: 60px ${theme.gutter};

  ${zIndex};
  ${borders};
  ${space};
`;

export const Quote = styled(H2)`
  margin: 0 auto 20px;

  color: ${styledMap("shade", {
    light: "white",
    default: theme.color.indigo
  })};
`;

export const Author = styled(Text)`
  margin: 0 auto;
  line-height: 1.7;
  font-size: ${theme.font.size.upsilon};

  color: ${styledMap("shade", {
    light: theme.color.indigo4,
    default: theme.color.gray2
  })};

  @media (max-width: ${theme.size.mobile}) {
    line-height: 1.4;
    font-size: ${theme.font.size.delta};
  }
`;
