import React, { Component } from 'react';
import Carousel from 'react-slick';

import { Wrapper, Inner } from './styles';
import ExperienceCard from './ExperienceCard';
import ArrowButton from './ArrowButton';

class Experiences extends Component {
  render() {
    const { content } = this.props;

    const settings = {
      dots: false,
      speed: 300,
      infinite: false,
      slidesToShow: 5,
      draggable: false,
      initialSlide: 1,
      nextArrow: <ArrowButton type="next" />,
      prevArrow: <ArrowButton type="previous" />,
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1550,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: true,
            centerPadding: '20px',
            slidesToShow: 1,
            initialSlide: 0
          }
        }
      ]
    };

    const experiences = content.filter(experience => {
      return experience.node.frontmatter.category.includes('Experience');
    });

    // const sortedExperiences = experiences.sort((a, b) =>
    //   a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
    // );

    return (
      <Wrapper>
        <Inner>
          <Carousel {...settings}>
            {experiences.map((experience, index) => (
              <ExperienceCard
                key={index}
                count={index}
                slug={experience.node.fields.slug}
                content={experience.node.frontmatter}
              />
            ))}
          </Carousel>
        </Inner>
      </Wrapper>
    );
  }
}

export default Experiences;
