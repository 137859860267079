import styled from "styled-components";
import styledMap from "styled-map";

import theme from "styles/theme";

import { H2, Text } from "components/Text";

export const Heading = styled(H2)`
  width: 100%;

  color: ${styledMap("shade", {
    light: "white",
    default: theme.color.indigo
  })};
`;

export const Content = styled(Text)`
  line-height: 1.7;

  color: ${styledMap("shade", {
    light: theme.color.indigo4,
    default: theme.color.gray2
  })};
`;
