import React from "react";
import styled from "styled-components";
import { space, height, top, left, bottom, zIndex, color } from "styled-system";

import theme from "styles/theme";

import { Image } from "components/Media";

const StyledImage = styled(Image)`
  width: 100%;
  height: 550px;
  object-fit: cover;
  display: block;
  position: ${props => props.position || "relative"} !important;

  @media (max-width: ${theme.size.mobile}) {
    height: 350px;

    ${height};
  }

  ${top};
  ${left};
  ${color};
  ${space};
  ${height};
  ${bottom};
  ${zIndex};
`;

const ImageBlock = ({ content, ...props }) => {
  if (!content) {
    return null;
  }

  return <StyledImage {...props} content={content} />;
};

export default ImageBlock;
