import React from 'react';

import { Wrapper, Title, Image, Count } from './styles';

const ExperienceCard = ({ slug, content, count }) => {
  const { page } = content;
  return (
    <Wrapper to={slug}>
      <Image content={page.image} />
      <Count>{count + 1}</Count>
      <Title>{page.title}</Title>
    </Wrapper>
  );
};

export default ExperienceCard;
