import styled from 'styled-components';
import { Link } from 'gatsby';
import { transparentize, darken } from 'polished';

import theme from 'styles/theme';
import { H2 } from 'components/Text';
import { Image as StyledImage } from 'components/Media';

export const Wrapper = styled(Link)`
  display: flex;
  padding: 40px 35px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  min-height: 340px;
  flex-direction: column;
  justify-content: space-between;
  transition: ${theme.transition};
  transform: scale(0.96);
  background-color: ${theme.color.teal};
  box-shadow: inset 0 0 0 1px ${transparentize(0.2, 'white')},
    0 2px 25px 0 ${transparentize(0.8, 'black')};

  &:focus {
    outline: none;
  }

  @media (max-width: ${theme.size.mobile}) {
    min-height: 280px;
    padding: 35px 35px 45px;
  }

  &:hover {
    transform: scale(0.98);
  }
`;

export const Title = styled(H2)`
  color: white;
  margin-bottom: 0;
  line-height: 1;
  position: relative;
  font-size: 32px;
  word-break: break-word;
  backdrop-filter: blur(3px);
  text-shadow: 0 0 2px ${transparentize(0.8, 'black')};

  @media (max-width: ${theme.size.mobile}) {
    font-size: 38px;
  }
`;

export const Image = styled(StyledImage)`
  height: 100%;
  width: 100%;
  max-width: none;
  object-fit: cover;
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    transition: ${theme.transition};
    background-image: linear-gradient(
      to top,
      ${transparentize(0, theme.color.teal)} 0%,
      ${transparentize(0.9, theme.color.teal)} 60%,
      ${transparentize(1, theme.color.teal)} 100%
    );
  }
`;

export const Count = styled.span`
  width: 45px;
  height: 45px;
  z-index: 1;
  display: flex;
  color: white;
  position: relative;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  font-size: ${theme.font.size.gamma};
  font-weight: ${theme.font.weight.bold};

  &::before {
    content: '';
    backdrop-filter: blur(3px);
    background-color: ${transparentize(0.6, darken(0.05, theme.color.teal))};
    width: 45px;
    height: 45px;
    position: absolute;
    z-index: -1;
    transform: rotate(45deg);
  }
`;
